<template>
  <div class="specs">
    <!--    <div class="specs">-->
    <div class="specs-cpu">
      <svg-icon size="icons" :title="$t('cpu')" name="cpu" class="specs-cpu__icon" />
      {{ specs.cpu }}
    </div>
    <div class="specs-item">
      <svg-icon size="icons" :title="$t('ram')" name="ram" class="specs-item__icon" />
      {{ specs.ram }} ГБ
    </div>
    <div class="specs-item">
      <div v-if="specs.local > 0" class="specs-item__row">
        <div class="specs-item__local">
          <svg-icon size="icons" :title="$t('local')" name="disk-l" class="specs-item__icon" />

          {{ specs.local }} ГБ
        </div>
        <!--        <div v-for="(spec, i) in specs.network" :key="i" class="specs-item__row-item">-->
        <div v-for="(spec, i) in specs.network" :key="i" class="specs-item__row-item">
          <!--          <svg-icon :title="$t('network')" name="local-disk" class="specs-item__icon" />-->
          <svg-icon
            size="icons"
            :title="$t(spec.type)"
            :name="spec.type"
            class="specs-item__icon"
          />
          <router-link
            :to="{ name: 'Disk', params: { diskid: spec.id } }"
            class="specs-item__row-item__link"
          >
            <!--              class="tariffs-table-item__link medium-text"-->
            <!--            {{ item.name ? item.name : item.id }}-->
            {{ spec.size }} ГБ
          </router-link>

          <!--          {{ spec.size }} ГБ-->
        </div>
      </div>
      <div v-else class="specs-item__row">
        <div v-for="(spec, i) in specs.network" :key="i" class="specs-item__row-item">
          <svg-icon
            size="icons"
            :title="$t(spec.type)"
            :name="spec.type"
            class="specs-item__icon"
          />
          <router-link
            :to="{ name: 'Disk', params: { diskid: spec.id } }"
            class="specs-item__row-item__link"
          >
            {{ spec.size }} ГБ
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import { dev } from '@/layouts/LoginPage/mixins/currentProvider';
import InputNumber from 'primevue/inputnumber';
export default {
  name: 'SpecsServer',
  components: {},
  mixins: [setFocus],
  props: {
    specs: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<i18n>
{
  "ru": {
    "SSD": "Сетевой диск SSD, ГБ",
    "SSD-Lite": "Сетевой диск SSD-Lite, ГБ",
    "HDD": "Сетевой диск HDD, ГБ",
    "local": "Локальный диск, ГБ",
    "ram": "Оперативная память, ГБ",
    "cpu": "Процессоры, штуки"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.specs {
  //text-overflow: unset;
  //overflow: visible;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;

  &-cpu {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 3rem;
    max-width: 5rem;
    padding: 0.75rem 0.75rem
    &__icon{
      margin-right: 0.25rem;

    }
  }

  &-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 5rem;
    max-width: 6rem;
    padding: 0.75rem 0.75rem

    //margin: 1.5rem 1.5rem;

    &__icon{
      margin-right: 0.25rem;

    }
    &__local{
      display: flex;
    }

    &__row{
      align-items: center;
      &-item {
        display: flex;
        align-items: center;
        //justify-content: space-between;
        //width: 4rem;
        &__link {
          position: relative;
          padding: 0.5em;
          margin: -0.5em;
          color: $primary-color;
          text-decoration: none;
          white-space: nowrap;
          background: inherit;
          &:hover {
            color: darken($primary-color, 20%);
          }

          &:hover::after {
            +breakpoint(ml-and-up) {
              content: '';
              display: block;
              size(2.5em, 100%);
              absolute(0px, 100%);
              background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
            }
          }
        }

      }
    }
//&-input {
//  padding: 1.5rem 1.5rem;
//}
  }
}
</style>
