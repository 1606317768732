import { render, staticRenderFns } from "./TariffsTableFlavors.vue?vue&type=template&id=73aef5ff&scoped=true"
import script from "./TariffsTableFlavors.vue?vue&type=script&lang=js"
export * from "./TariffsTableFlavors.vue?vue&type=script&lang=js"
import style0 from "./TariffsTableFlavors.vue?vue&type=style&index=0&id=73aef5ff&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73aef5ff",
  null
  
)

/* custom blocks */
import block0 from "./TariffsTableFlavors.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports