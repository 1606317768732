<template>
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <template #name>
      {{ item.name }}
      <div class="tariffs-table-item__specs note-text">
        {{ item.description }}
      </div>
    </template>
    <template #id>
      <div class="tariffs-table-item__ip">
        {{ item.id }}
      </div>
    </template>
    <template #config>
      <specs-server :specs="{ cpu: cpu, ram: ram, local: disk }"></specs-server>
    </template>
    <template #inst>
      <router-link
        v-if="attachToServer"
        :to="{ name: 'Server', params: { serverid: attachToServer.id } }"
        class="tariffs-table-item__link medium-text"
      >
        {{ attachToServer.name }}
      </router-link>
    </template>
    <template #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-table-item__popover"
      >
        <plain-button icon="more" class="tariffs-table-item__more" @click="togglePopoverState" />
        <flavor-context-menu
          slot="popover"
          :tariff="item"
          :deletable="!attachToServer"
          class="tariff-table-item__context-menu"
        />
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import FlavorContextMenu from './FlavorContextMenu';
import popoverFix from '@/mixins/popoverFix';
import SpecsServer from '@/layouts/Stack/components/SpecsServer';
export default {
  name: 'TariffsTableFlavorsRow',
  components: {
    BaseTableRow,
    SpecsServer,
    FlavorContextMenu,
  },
  mixins: [popoverFix],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cpu() {
      return this.item.vcpus ? this.item.vcpus : null;
    },
    ram() {
      return this.item.ram ? this.item.ram / 1024 : null;
    },
    disk() {
      return this.item.disk ? this.item.disk : null;
    },
    attachToServer() {
      return this.$store.state.moduleStack.servers.find(
        server => server.flavor.original_name === this.item.name
      );
    },
  },
  methods: {
    concatString(obj) {
      let str = obj.value;
      if (obj.unit) str += ` ${obj.unit}`;
      return str;
    },
    printParam(param, payload) {
      return param.toString(payload);
    },
    isBootable(item) {
      return item.bootable === 'true' ? 'Загрузочный' : ' - ';
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "activeUnder": "До {date} г.",
    "mb": "{num} МБ",
    "gb": "{num} ГБ"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.v-popover {
  text-align: center;
}
.tariffs-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: visible;
      z-index: 2;
    }
  }
  &__ip {
    //text-align: right;
  }

  &__link {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    color: $primary-color;
    text-decoration: none;
    white-space: nowrap;
    background: inherit;
    &:hover {
      color: darken($primary-color, 20%);
    }

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-table-item {
  &__popover {
    text-align: left
    text-align: center;
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;

      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      width: calc(100vw - 6rem);
    }
  }
}
</style>
